<template>
  <div class="page catalog-page" :class="{ 'page-sections__even': !showCategories }">
    <BreadcrumbsComponent :links="links" :title="pageTitle" />
    <div class="page__inner">
      <!--      <div class="container-padding catalog-page__filter-mobile">-->
      <!--        <button @click="showFilterMenu" class="btn btn&#45;&#45;md btn&#45;&#45;gray-hollow">-->
      <!--          <IconComponent name="settings-2" />-->
      <!--          <span>Фильтр</span>-->
      <!--        </button>-->
      <!--      </div>-->
      <SectionComponent v-if="showCategories" class="catalog-page__banners">
        <!--        <BannersComponent :banners="banners" />-->
        <CategoriesComponent :categories="categories" v-if="showCategories" />
        <!--        <BenefitsComponent class="catalog-page__benefits" v-if="!showCategories" border />-->
      </SectionComponent>
      <!--      <SectionComponent v-if="!showCategories" class="catalog-page__popular-products">-->
      <!--        <ProductsComponent :products="4" title="Для детей, мам и пап" row />-->
      <!--      </SectionComponent>-->
      <!--      <SectionComponent class="catalog-page__shares">-->
      <!--        <SharesComponent :shares="shares" />-->
      <!--      </SectionComponent>-->
      <SectionComponent ref="productsList" class="catalog-page__products" v-if="!showCategories">
        <div class="catalog-page__body">
          <CatalogFiltersComponent @filter="filter" :loading="filterLoading" />
          <div
            :style="{ opacity: filterLoading || paginateLoading ? 0.7 : 1 }"
            v-if="showProducts"
            class="catalog-page__products-list"
          >
            <ProductsComponent :products="products.data" />
            <PaginationComponent :page="page" :first="first" :list="products" @change="paginate" />
          </div>
          <span v-else-if="$store.state.variables">По данному запросу ничего не найдено</span>
          <span v-else>В данной категории товары отсутвуют</span>
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
// import HOME_PAGE from "@/graphql/pages/HomePage.graphql";
import BenefitsComponent from "components/BenefitsComponent.vue";
import CategoriesComponent from "components/CategoriesComponent.vue";
import BannersComponent from "components/BannersComponent.vue";
import ProductsComponent from "components/ProductsComponent.vue";
import SharesComponent from "components/SharesComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import CatalogFiltersComponent from "./components/CatalogFiltersComponent.vue";
import CATALOG_PAGE from "@/graphql/pages/CatalogPage.graphql";
import CATALOG_PAGE_CATEGORY from "@/graphql/pages/CatalogPageCategory.graphql";
import CATALOG_PAGE_BRAND from "@/graphql/pages/CatalogPageBrand.graphql";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import FilterMenu from "components/menus/components/FilterMenu.vue";
import PaginationComponent from "components/Pagination.vue";

let _isMobile = false;
let _query = {};

// спарсить численные параметры из строчных
function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

export default {
  name: "CatalogPage",
  async asyncData({ apollo, store, route, isMobile }) {
    _isMobile = isMobile;
    _query = parseParams(route.query);

    let query = CATALOG_PAGE;
    if (route.params.id) {
      query = CATALOG_PAGE_CATEGORY;
    } else if (_query.brands_item_id) {
      query = CATALOG_PAGE_BRAND;
      _query.brand_id = [_query.brands_item_id];
    }
    await apollo.defaultClient
      .query({
        query: query,
        variables: {
          parent: true,
          category_id: route.params.id ? parseInt(route.params.id) : undefined,
          // ordering: sortTypes[0].ordering ? [sortTypes[0].ordering] : undefined,
          first: _isMobile ? 12 : 11,
          ..._query,
        },
      })
      .then(({ data }) => {
        store.state.catalog_page.products = data.products_paginate;
        store.state.catalog_page.banners = data.banners || [];
        store.state.catalog_page.categories = data.categories || [];
        store.state.catalog_page.category = data.category;
        store.state.catalog_page.shares = data.shares || [];
        store.state.catalog_page.filter_attributes = data.filter_attributes || [];
        store.state.catalog_page.brand = data.brands_item;
        store.state.catalog_page.products_min_max_price = data.products_min_max_price;
        store.state.global.brands = data.brands;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  data() {
    return {
      page: 1,
      first: _isMobile ? 12 : 11,
      query: _query,
      filterLoading: false,
      paginateLoading: false,
      params: {},
    };
  },
  watch: {
    "$store.state.variables": {
      handler() {
        this.filter();
      },
      deep: true,
    },
  },
  computed: {
    showCategories() {
      return (!this.category || (this.category.childs && this.category.childs.length)) && !this.brand;
    },
    pageTitle() {
      if (this.category) {
        return this.category.title;
      }
      if (this.brand) {
        return this.brand.title;
      }
      return "Каталог";
    },
    metaDescription() {
      if (this.category) {
        return this.category.meta_description;
      }
      if (this.brand) {
        return this.brand.meta_description;
      }
      return null;
    },
    metaKeywords() {
      if (this.category) {
        return this.category.meta_keywords;
      }
      if (this.brand) {
        return this.brand.meta_keywords;
      }
      return null;
    },
    links() {
      let links = [];
      if (this.category) {
        let category = this.category.parent;
        while (category) {
          links.unshift({
            title: category.title,
            route: {
              name: "catalog",
              params: { id: category.id },
            },
          });
          category = category.parent;
        }
        links.unshift({ title: "Каталог", route: { name: "catalog" } });
      } else if (this.brand && this.brand.title) {
        links = [{ title: "Бренды", route: { name: "" } }];
      }
      return links;
    },
    banners() {
      return this.$store.state.catalog_page.banners;
    },
    shares() {
      return this.$store.state.catalog_page.shares;
    },
    categories() {
      if (this.category && this.category.childs && this.category.childs.length) {
        return this.category.childs;
      }
      return this.$store.state.catalog_page.categories || [];
    },
    category() {
      return this.$store.state.catalog_page.category;
    },
    brand() {
      return this.$store.state.catalog_page.brand;
    },
    products() {
      return this.$store.state.catalog_page.products;
    },
    showProducts() {
      return this.products && this.products.data && this.products.data.length;
    },
    showPagination() {
      return this.showProducts && Math.ceil(this.products.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    async changeSort(sort) {
      this.activeSort = sort;
      this.page = 1;
      this.paginateLoading = true;
      await this.load();
    },
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async filter() {
      this.page = 1;
      this.filterLoading = true;
      await this.load();
      this.scrollUp();
    },
    async load() {
      let query = CATALOG_PAGE;
      if (this.category) {
        query = CATALOG_PAGE_CATEGORY;
      } else if (this.brand) {
        query = CATALOG_PAGE_BRAND;
      }
      await this.$apollo.provider.defaultClient
        .query({
          query: query,
          variables: {
            parent: true,
            category_id: this.category ? this.category.id : undefined,
            // ordering: this.activeSort.ordering ? [this.activeSort.ordering] : undefined,
            first: this.first,
            page: this.page,
            ...this.params,
            ...this.$store.state.variables,
          },
        })
        .then(({ data }) => {
          this.filterLoading = false;
          this.paginateLoading = false;
          this.$store.state.catalog_page.products = data.products_paginate;
        })
        .catch(() => {
          this.filterLoading = false;
          this.paginateLoading = false;
        });
    },
    scrollUp() {
      if (this.$refs.productsList && window.scrollY > this.$refs.productsList.$el.offsetTop) {
        window.scrollTo({ top: this.$refs.productsList.$el.offsetTop - 120 });
      }
    },
    showFilterMenu() {
      this.$store.state._menus.push({
        component: FilterMenu,
        options: {},
      });
    },
  },
  components: {
    PaginationComponent,
    IconComponent,
    BreadcrumbsComponent,
    CatalogFiltersComponent,
    SectionComponent,
    SharesComponent,
    ProductsComponent,
    BannersComponent,
    CategoriesComponent,
    BenefitsComponent,
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      description: this.metaDescription,
      keywords: this.metaKeywords,
    };
  },
};
</script>

<style lang="stylus">
.catalog-page {
  +below(990px) {
    &__banners {
      order 3
    }

    &__popular-products {
      order 2
    }

    &__shares {
      order 4
    }

    &__products {
      order 1
    }
  }

  &__body {
    display grid
    grid-template-columns minmax(280px, 320fr) 1042fr
    grid-gap var(--gap)
    align-items start
    +below(990px) {
      grid-template-columns 1fr
    }
  }

  &__products-list {
    display flex
    flex-direction column
    gap: 40px
  }

  &__filter-mobile {
    +above(991px) {
      display none
    }
    +below(480px) {
      width 100%
    }
  }
}
</style>
